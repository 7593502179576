<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 18C1.45 18 0.979 17.8043 0.587 17.413C0.195667 17.021 0 16.55 0 16V2C0 1.45 0.195667 0.979 0.587 0.587C0.979 0.195667 1.45 0 2 0H9V2H2V16H9V18H2ZM13 14L11.625 12.55L14.175 10H6V8H14.175L11.625 5.45L13 4L18 9L13 14Z" />
        </svg>
</template>
<script>
export default {
  name: 'icon-logout',
  // props: {
  //   fill_color: {
  //     type: String,
  //     default: '#000'
  //   }
  // }
  // :fill="fill_color"
}
</script>